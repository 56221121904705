<template>
  <div class="payFail">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source />
      <div class="navTitle">问题详情</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <div class="title">如果重复购买了会员怎么办？</div>
      <div class="content">
        同一个账号开通两次会员，会员时长将会根据购买的顺延，不会重叠时间，请您核实账号的到期时间，是否延长两次订购的时长。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.payFail {
  height: 100%;
  color: #000;
  // background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-bottom: 0.5px solid #f3f3f3;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 18px;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }
  .main {
    height: calc(100% - 45px);
    overflow-y: auto;
    padding: 0 12px;
    box-sizing: border-box;
    font-size: 14px;
    .title {
      font-weight: 600;
      margin: 12px 0;
      color: #000;
    }
    .content {
      line-height: 26px;
      margin-bottom: 36px;
      font-weight: 400;
      color: #333;
    }
  }
}
</style>
